import React from "react";
import PropTypes from "prop-types";
import { ContactContainer } from "./Contact.styled";
import FormWithAddress from "./FormWithAddress/FormWithAddress";
import { ServicesPageContainer } from "../ServicesPage/ServicesPage.styled";

const Contact = ({ content }) => {
  return (
    <ContactContainer fullWidth>
      <ServicesPageContainer
        title={content.pageTitle}
        subtitle={content.subTitle}
        secondary
      >
        <FormWithAddress
          formBody={content.formBody}
          contact={content.contact}
        ></FormWithAddress>
      </ServicesPageContainer>
    </ContactContainer>
  );
};

Contact.propTypes = {
  content: PropTypes.shape({
    pageTitle: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    formBody: PropTypes.string.isRequired,
    contact: PropTypes.shape({
      address: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      website: PropTypes.string.isRequired,
    }),
  }),
};

export default Contact;
