import React, { useState } from "react";
import PropTypes from "prop-types";
import { navigateTo } from "gatsby-link";
import {
  FormStyled,
  Label,
  TextAreaContainer,
  Input,
  Textarea,
  FormContainer,
} from "./Form.styled";
import Button from "../../../UI/Button/Button";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Form = ({ body }) => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
    subject: "",
  });

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  function handleSubmit(e) {
    e.preventDefault();
    const submitForm = e.target;
    const formName = submitForm.getAttribute("name");
    const action = submitForm.getAttribute("action");
    if (formName && action) {
      fetch("/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: encode({
          "form-name": formName,
          ...form,
        }),
      })
        .then(() => navigateTo(action))
        .catch((error) => alert(error));
    }
  }
  return (
    <FormContainer>
      <section dangerouslySetInnerHTML={{ __html: body }}></section>
      <FormStyled
        name="contact-form"
        method="post"
        action="/thanks"
        netlify-honeypot="bot-field"
        data-netlify="true"
        onSubmit={handleSubmit}
      >
        <input
          type="hidden"
          name="bot-field"
          aria-hidden="true"
          aria-label="bot-field"
        />
        <div>
          <Label htmlFor="name">Name:</Label>
          <Input
            type="text"
            name="name"
            id="name"
            placeholder="Your name"
            required
            onChange={handleChange}
          />
        </div>
        <div>
          <Label htmlFor="email">Email:</Label>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="Your email"
            required
            onChange={handleChange}
          />
        </div>
        <div>
          <Label htmlFor="subject">Subject:</Label>
          <Input
            type="text"
            name="subject"
            id="subject"
            placeholder="Subject Line"
            onChange={handleChange}
          />
        </div>
        <TextAreaContainer>
          <Label htmlFor="message">Message:</Label>
          <Textarea
            name="message"
            id="message"
            rows="5"
            placeholder="Type your message here"
            required
            onChange={handleChange}
          />
        </TextAreaContainer>
        <Button small type="submit">
          Send
        </Button>
      </FormStyled>
    </FormContainer>
  );
};

Form.propTypes = {
  body: PropTypes.string.isRequired,
};

export default Form;
