import React from "react";

import Layout from "../components/Layout/layout";
import SEO from "../components/seo";
import Contact from "../components/Contact/Contact";

const content = {
  pageTitle: "Contact Us",
  subTitle:
    "Digital Marketing Services Made More Affordable: Contact Serbay Digital Today",
  formBody:
    "<h2>THANK YOU FOR YOUR INTEREST! NOW, WE WANT TO HEAR FROM YOU</h2><p>Are you a small business or a new entrepreneur in need of premium yet affordable digital marketing services? You’ve come to the right place! Serbay Digital is your partner in creating a lasting digital impact that converts visitors to customers and clicks to revenues.</p><p>As a website development company, Serbay Digital can handle all aspects of your online presence, from your website to your social media pages. We can even develop a web or mobile app for your business.</p><p>Even better, you can have this complete suite of solutions for an affordable price. If there’s one thing we don’t like, it’s business owners and entrepreneurs spending thousands on ineffective and low-quality services.</p><p>Now, it’s your turn to tell us more about yourself. Leave us a message on the form below, and we can work on a tailored campaign that will put your business on the map.</p>",
  contact: {
    website: "serbaydigital.com",
  },
};

const ContactPage = () => (
  <Layout>
    <SEO
      title="Contact Serbay Digital To Get Affordable Digital Services"
      description="Are you ready to experience premium digital marketing services for a fraction of the price? Don’t hesitate and reach out to Serbay Digital today"
    />
    <Contact content={content}></Contact>
  </Layout>
);

export default ContactPage;
