import styled from "styled-components";

export const AddressContainer = styled.div`
  align-self: start;
  background: #ffffff;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 2rem;
  max-width: 25rem;
`;

export const AddressTag = styled.address`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 1rem;
  margin: 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;

  /* or 156% */

  color: #1c2f3e;
  & a {
    text-decoration: none;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;

    /* or 156% */

    color: #1c2f3e;
    transition: color 0.2s ease-in;
    :hover {
      color: #6272ea;
    }
  }
`;
