import styled from "styled-components";

export const FormContainer = styled.div`
  width: 100%;
  justify-self: center;
`;

export const FormStyled = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  @media screen and (min-width: ${({ theme }) => theme.tabletScreen}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Label = styled.label`
  display: block;
  font-family: "Crimson Text";
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  line-height: 38px;

  /* or 237% */

  color: #aeaeae;
`;

export const Input = styled.input`
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: thin solid #bcbcbc;
  background: transparent;
`;

export const TextAreaContainer = styled.div`
  grid-column: 1/-1;
`;

export const Textarea = styled.textarea`
  width: 100%;
  background: transparent;
  border: none;
  border-bottom: thin solid #bcbcbc;
`;
