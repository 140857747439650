import styled from "styled-components";
import React from "react";
import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(
          relativePath: { eq: "bg/bg-contact-serbay-digital.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#efefef`}
        >
          {children}
        </BackgroundImage>
      );
    }}
  />
);

export const ContactContainer = styled(BackgroundSection)`
  grid-column: ${props =>
    props.fullWidth ? "left-gutter-start/right-gutter-end" : "content"};
  display: inherit;
  grid-template-columns: inherit;
  align-content: start;
  justify-items: center;
  padding: ${props =>
    props.padding === true ? "0 2rem" : props.padding ? props.padding : null};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
