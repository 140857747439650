import React from "react";
import PropTypes from "prop-types";
import { AddressContainer, AddressTag } from "./Address.styled";
import { contactMarker, contactPhone, contactWeb } from "./icons";
import { Heading3 } from "../../../UI/Typography/Typography.styled";

const Address = ({ contact }) => {
  return (
    <AddressContainer>
      <Heading3 as="h2" secondary>
        Contact Information
      </Heading3>
      <AddressTag>
        <span>{contactWeb}</span>
        <a href={`https://www.${contact.website}/contact`}>
          WEBSITE: {contact.website}
        </a>
      </AddressTag>
    </AddressContainer>
  );
};

Address.propTypes = {
  contact: PropTypes.shape({
    address: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    website: PropTypes.string.isRequired,
  }),
};

export default Address;
