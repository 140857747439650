import React from "react";
import PropTypes from "prop-types";
import { Container } from "./FormWithAddress.styled";
import Form from "./Form/Form";
import Address from "./Address/Address";

const FormWithAddress = ({ formBody, contact }) => {
  return (
    <Container>
      <Form body={formBody}></Form>
      <Address contact={contact}></Address>
    </Container>
  );
};

FormWithAddress.propTypes = {
  formBody: PropTypes.string.isRequired,
  address: PropTypes.shape({
    address: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    website: PropTypes.string.isRequired,
  }),
};

export default FormWithAddress;
